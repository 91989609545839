define("ember-file-upload/system/http-request", ["exports", "ember-file-upload/system/trim", "ember-file-upload/system/parse-html", "ember-file-upload/system/parse-xml", "ember-file-upload/system/parse-json"], function (_exports, _trim, _parseHtml, _parseXml, _parseJson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function getHeader(headers, header) {
    var headerKeys = Object.keys(headers);
    var headerIdx = headerKeys.map(function (key) {
      return key.toLowerCase();
    }).indexOf(header.toLowerCase());
    if (headerIdx !== -1) {
      return headers[headerKeys[headerIdx]];
    }
    return null;
  }
  function parseResponse(request) {
    var body = (0, _trim.default)(request.responseText);
    var rawHeaders = request.getAllResponseHeaders().split(/\n|\r/).filter(function (header) {
      return header !== '';
    });
    var headers = rawHeaders.reduce(function (E, header) {
      var parts = header.split(/^([0-9A-Za-z_-]*:)/);
      if (parts.length > 0) {
        E[parts[1].slice(0, -1)] = (0, _trim.default)(parts[2]);
      }
      return E;
    }, {});
    var contentType = (getHeader(headers, 'Content-Type') || '').split(';');

    // Parse body according to the Content-Type received by the server
    if (contentType.indexOf('text/html') !== -1) {
      body = (0, _parseHtml.default)(body);
    } else if (contentType.indexOf('text/xml') !== -1) {
      body = (0, _parseXml.default)(body);
    } else if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('application/vnd.api+json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
      body = (0, _parseJson.default)(body);
    }
    return {
      status: request.status,
      body: body,
      headers: headers
    };
  }
  function _default() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _Ember$RSVP$defer = Ember.RSVP.defer("ember-file-upload: ".concat(options.label)),
      resolve = _Ember$RSVP$defer.resolve,
      reject = _Ember$RSVP$defer.reject,
      promise = _Ember$RSVP$defer.promise;
    var request = new XMLHttpRequest();
    request.withCredentials = options.withCredentials;
    var aborted;
    promise.cancel = function () {
      if (aborted == null) {
        aborted = Ember.RSVP.defer("ember-file-upload: Abort ".concat(options.label));
        request.abort();
      }
      return aborted.promise;
    };
    promise.then = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      var newPromise = Ember.RSVP.Promise.prototype.then.apply(this, args);
      newPromise.cancel = promise.cancel;
      newPromise.then = promise.then;
      return newPromise;
    };
    request.onabort = Ember.run.bind(this, function () {
      this.onabort();
      aborted.resolve();
    });
    this.setRequestHeader = function (header, value) {
      request.setRequestHeader(header, value);
    };
    this.open = function (method, url, _) {
      var username = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
      var password = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
      request.open(method, url, true, username, password);
    };
    this.send = function (data) {
      request.send(data);
      return promise;
    };
    this.onprogress = this.onprogress || function () {};
    this.ontimeout = this.ontimeout || function () {};
    this.onabort = this.onabort || function () {};
    request.onloadstart = request.onprogress = request.onloadend = Ember.run.bind(this, function (evt) {
      this.onprogress(evt);
    });
    if (request.upload) {
      request.upload.onprogress = request.onprogress;
    }
    request.onload = Ember.run.bind(this, function () {
      var response = parseResponse(request);
      if (Math.floor(response.status / 200) === 1) {
        resolve(response);
      } else {
        reject(response);
      }
    });
    request.onerror = Ember.run.bind(this, function () {
      reject(parseResponse(request));
    });
    Object.defineProperty(this, 'timeout', {
      get: function get() {
        return request.timeout;
      },
      set: function set(timeout) {
        request.timeout = timeout;
      },
      enumerable: true,
      configurable: false
    });
    request.ontimeout = Ember.run.bind(this, function () {
      this.ontimeout();
      reject(parseResponse(request));
    });
  }
});